<template>
  <div>
    <h2 v-html="$t('search_segmentation_grouping_title')"></h2>
    <!-- Segment einzeln -->
    <v-switch
      id="search_segmentation_grouping_segmentation_type"
      v-model="searchCardStore.groupBySegmentationType"
      color="primary"
      :label="$t('search_segmentation_grouping_segmentation_type')"
      @keyup.enter="apply()"
    ></v-switch>
    <!-- Fütterung einzeln -->
    <v-switch
      id="search_segmentation_grouping_feeding"
      v-model="searchCardStore.groupByFeeding"
      color="primary"
      :label="$t('search_segmentation_grouping_feeding')"
      @keyup.enter="apply()"
    ></v-switch>
    <!-- Monate einzeln -->
    <v-switch
      id="search_segmentation_grouping_date"
      v-model="searchCardStore.groupByDate"
      color="primary"
      :label="$t('search_segmentation_grouping_date')"
      @keyup.enter="apply()"
    ></v-switch>
    <!-- Gesuchsteller einzeln -->
    <v-switch
      id="search_segmentation_grouping_dairy"
      v-model="searchCardStore.groupByDairy"
      color="primary"
      :label="$t('search_segmentation_grouping_dairy')"
      @keyup.enter="apply()"
    ></v-switch>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { useSearchSegmentationGroupingStore } from '@/store/SearchSegmentationGroupingStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchSegmentationGrouping',
  extends: baseSearchVue,
  data() {
    return {}
  },
  methods: {
    apply() {
      this.search()
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchSegmentationGroupingStore(), true)
  }
})
</script>
