import { TermItemDefinition } from '@/services/term'
import { defineStore } from 'pinia'
export const useSearchSegmentationGroupingStore = defineStore('searchSegmentationGroupingStore', {
  state: () => ({
    groupBySegmentationType: true,
    groupByFeeding: true,
    groupByDate: true,
    groupByDairy: true,
    items: [
      { key: 'groupBySegmentationType', operation: ':' },
      { key: 'groupByFeeding', operation: ':' },
      { key: 'groupByDate', operation: ':' },
      { key: 'groupByDairy', operation: ':' }
    ] as TermItemDefinition[]
  })
})
